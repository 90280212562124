import { render, staticRenderFns } from "./ingress-specific.vue?vue&type=template&id=e977fb72&scoped=true"
import script from "./ingress-specific.vue?vue&type=script&lang=ts"
export * from "./ingress-specific.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e977fb72",
  null
  
)

export default component.exports