import { render, staticRenderFns } from "./AdminHelpModify.vue?vue&type=template&id=aab1ee00&scoped=true"
import script from "./AdminHelpModify.vue?vue&type=script&lang=ts"
export * from "./AdminHelpModify.vue?vue&type=script&lang=ts"
import style0 from "./AdminHelpModify.vue?vue&type=style&index=0&id=aab1ee00&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aab1ee00",
  null
  
)

export default component.exports