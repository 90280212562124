
import { Component, Vue } from 'vue-property-decorator';
import {IHelp, HELP_CATEGORY} from '@/types/board';
import NoticeTable from "@/components/help/NoticeTable.vue";

@Component({
  components: {
    NoticeTable,
  }
})
export default class AdminHelpList extends Vue {
  loading = true;
  helpList: IHelp[] = [];
  page = 1;
  totalRow = 0;
  limitPage = 10;
  search = '';
  searchButtonHover = true;
  category= this.$route.params.category;
  tabIndex = Number(this.$route.query.tabIndex) || 0;

  async created() {
    await this.load();
  }

  /*activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }
*/
  async load() {
    try {
      const sendData = {
        limit: this.limitPage,
        page: this.page,
        search: this.search,
      };
      const { data } = await this.axios.get(`/board/select-help`, { params: sendData });
      const { result, count, list } = data;
      if (result == true) {
        this.helpList = list;
        this.totalRow = count;
      }
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  }
/*
  get helpListLength(): number {
    return this.helpList.length;
  }*/

/*  categoryVariant(category: HELP_CATEGORY): string {
    if (category === HELP_CATEGORY.NOTICE) return 'warning';
    else if (category === HELP_CATEGORY.SCRIPT) return 'success';
    else return 'primary';
  }*/

/*  helpView(index: number) {
    const list: IHelp = this.helpList[index];
    const { _id } = list;
    this.$router.push(`/admin/qna-read/${_id}`);
  }*/
}
